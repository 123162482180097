import styled from "@emotion/styled";

export const Container = styled.div`
  max-width: 1260px;
  margin: 80px auto;
  text-align: center;
  padding: 0 30px;

  h1 {
    font-size: 72px;
    -webkit-text-stroke: 3px #004aad;
    -webkit-text-fill-color: white;
  }

  @media (max-width: 900px) {
    padding: 10px 20px;
    margin: 30px auto;

    h1 {
      font-size: 60px;
    }
  }

  @media (max-width: 480px) {
    margin: 30px auto;
    padding: 0;

    h1 {
      font-size: 30px;
      -webkit-text-stroke: 1px #004aad;
    }
  }
`

export const Contacts = styled.div`
  margin: 0 auto 20px;
  max-width: 800px;
  display: grid;
  grid-template-columns: 50% 50%;
  column-gap: 10px;
  text-align: left;
  font-weight: 300;

  @media (max-width: 900px) {
    display: block;
    margin: 0 auto;
  }
`

export const ContactContent = styled.div`
  max-width: 400px;
  display: grid;
  grid-template-columns: 60px auto;
  font-size: 24px;

  a {
    color: #000;
    text-decoration: none;
  }

  @media (max-width: 900px) {
    margin: 0 auto 20px;
  }

  @media (max-width: 480px) {
    padding: 0 30px;
    font-size: 18px;
    grid-template-columns: 30px auto;
  }
`

export const Box = styled.div`
  background-color: #e8e8e8;
  margin: 30px;
  padding: 30px 60px;
  font-size: 30px;
  font-weight: 300;

  @media (max-width: 900px) {
    font-size: 24px;
  }

  @media (max-width: 480px) {
    padding: 30px;
  }
`

export const Location = styled.div`
  padding: 20px 40px;
  margin: 0 auto;

  span {
    display: block;
  }

  @media (max-width: 900px) {
      
  }

  @media (max-width: 480px) {
    padding: 0 30px;
    grid-template-columns: 30px auto;
  }
`

export const LocationInfo = styled.div`
  display: grid;
  grid-template-columns: 30px auto;
  text-align: left;
  font-size: 24px;
  font-weight: 300;
  margin-bottom: 30px;

  @media (max-width: 480px) {
    font-size: 18px;
  }
`
